import moment from 'moment';
import FilterFactory from '@/lib/layouts/page/filterFactory';
import { phoneMask } from '@/lib/layouts/page/filterMasks';
import { FilterEntityModel } from '@/lib/layouts/page/filter.interface';

export default class ReactivationFilter {
  filterHandlers = {
    update: 'reactivationFilter/updateFilter',
    reset: 'reactivationFilter/resetFilter',
    resetState: 'reactivationFilter/resetFilterState',
    removeSelected: 'reactivationFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setClassName('fullName', 'col-1-row-1');
    filter.setAction('fullName', 'reactivationFilter/updateSearch');

    filter.createSearchEntity('phone');
    filter.setPlaceholder('phone', 'По номеру телефона');
    filter.setName('phone', 'search_phone');
    filter.setPreviewName('phone', 'Тел');
    filter.setClassName('phone', 'col-1-row-2');
    filter.setIconClassName('phone', 'icon-phone');
    filter.setAction('phone', 'reactivationFilter/updateSearch');
    filter.setMask('phone', phoneMask);

    filter.createMultiSelectEntity('competences');
    filter.setTitle('competences', 'По компетенции');
    filter.setName('competences', 'multi_select_competences');
    filter.setPreviewName('competences', 'Компетенц');
    filter.setInitAction('competences', 'reactivation/initCompetences');
    filter.setClassName('competences', 'col-1-row-3');
    filter.setIconClassName('competences', 'icon-book');
    filter.setAction('competences', 'reactivationFilter/updateMultiSelect');
    filter.setSearchEnabled('competences', true);

    filter.createSelectEntity('client');
    filter.setTitle('client', 'По клиенту');
    filter.setName('client', 'select_client');
    filter.setPreviewName('client', 'Клиент');
    filter.setClassName('client', 'col-2-row-1');
    filter.setIconClassName('client', 'icon-clients');
    filter.setAction('client', 'reactivation/updateClient');
    filter.setInitAction('client', 'reactivation/initClientsSelect');
    filter.setClearAction('client', 'reactivation/resetClient');
    filter.setSearchEnabled('client', true);

    filter.createMultiSelectEntity('employeeStatuses');
    filter.setTitle('employeeStatuses', 'По статусу');
    filter.setName('employeeStatuses', 'multi_select_employeeStatuses');
    filter.setPreviewName('employeeStatuses', 'Статус');
    filter.setClassName('employeeStatuses', 'col-2-row-2');
    filter.setIconClassName('employeeStatuses', 'icon-status');
    filter.setAction('employeeStatuses', 'reactivationFilter/updateMultiSelect');
    filter.setInitAction('employeeStatuses', 'reactivation/initEmployeeStatusesSelect');

    const nowDate = moment();
    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Дата');
    filter.setClassName('date', 'col-2-row-3 cp-date-filter-range-wrapper_full');
    filter.setInitAction('date', 'reactivationFilter/initDates');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'dayStart',
        type: 'date-picker',
        title: 'Назад',
        placeholder: '',
        value: '',
        action: 'reactivationFilter/updateStartDate',
        blurAction: 'reactivationFilter/blurStartDate',
        min: nowDate.clone().add(-30, 'days').format('DD/MM/YY'),
        max: nowDate.clone().add(-1, 'day').format('DD/MM/YY'),
        withClear: true,
      },
      endDate: {
        key: 'dayEnd',
        type: 'date-picker',
        title: 'Вперед',
        placeholder: '',
        value: '',
        action: 'reactivationFilter/updateEndDate',
        blurAction: 'reactivationFilter/blurEndDate',
        min: nowDate.clone().add(1, 'day').format('DD/MM/YY'),
        max: nowDate.clone().add(30, 'days').format('DD/MM/YY'),
        withClear: true,
      },
    });

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'reactivationFilter/updateCheckbox');
    filter.setClassName('isNotTest', 'col-2-row-4');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    filter.createSearchEntity('noShiftsPast');
    filter.setName('noShiftsPast', 'search_noShiftsPast');
    filter.setPreviewName('noShiftsPast', 'Назад');
    filter.setIconClassName('noShiftsPast', 'icon-calendar');
    filter.setClassName('noShiftsPast', 'hidden');
    filter.setAction('noShiftsPast', 'reactivationFilter/updateNoShifts');
    this.watchNoShiftsModel(filter, 'noShiftsPast');

    filter.createSearchEntity('noShiftsFuture');
    filter.setName('noShiftsFuture', 'search_noShiftsFuture');
    filter.setPreviewName('noShiftsFuture', 'Вперед');
    filter.setIconClassName('noShiftsFuture', 'icon-calendar');
    filter.setClassName('noShiftsFuture', 'hidden');
    filter.setAction('noShiftsFuture', 'reactivationFilter/updateNoShifts');
    this.watchNoShiftsModel(filter, 'noShiftsFuture');

    this.filterModel = filter.filterModel;
  }

  private watchNoShiftsModel(filter: FilterFactory, noShiftsKey: string): void {
    const previewName = filter.filterModel[noShiftsKey].previewName;

    filter.filterModel[noShiftsKey] = new Proxy(filter.filterModel[noShiftsKey], {
      set(target: FilterEntityModel, property: keyof FilterEntityModel, value: string) {
        if (property === 'value') {
          filter.setPreviewName(noShiftsKey, `${previewName}: ${value}`);
        }

        (target[property] as string) = value;

        return true;
      },
    });
  }
}

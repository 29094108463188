
import { Component, Vue } from '@/lib/decorator';
import { ref } from 'vue';

import TasksEntityModule from '@/store/tasks/entity';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import AppTable from '@/components/ui/table/Table.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import ReactivationModule from '@/store/tasks/reactivation/index';
import IconFont from '@/components/icons/IconFont.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ModalBlock from '@/components/Modal.vue';
import ReactivationCard from './_card.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { getEmployeePhone } from '@/api/employees';
import { makePhoneCall } from '@/lib/util/phone';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { FilterModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { Checkbox } from '@/interfaces/filter.interface';
import { GuiFormCheckbox, GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    GuiFormCheckbox,
    TextDatetime,
    TabsNav,
    TitleReturn,
    AppHeaderMain,
    AppTable,
    FormMultiSelect,
    FormButton,
    IconFont,
    TextPhone,
    ModalBlock,
    ReactivationCard,
    AppRow,
    AppCol,
    AppSimpleCheckbox,
    FormSimpleSwitch,
  },
})
export default class ReactivationMain extends Vue {
  filter = ref(null);

  statuses: Record<string, string> = {
    not_working: 'Не работает',
    no_shifts: 'Нет смен',
    few_shifts: 'Мало смен',
    need_calendar: 'Нужен график',
    beginner: 'Новичок',
    beginner_calendar: 'Новичок с графиком',
    beginner_add: 'Новичок запланирован',
    is_working: 'Работает',
    calendar_end: 'График заканчивается',
  };

  disabledViewCheckboxes: string[] = [];

  get modalTitle(): string {
    const { statuses } = this;

    return statuses[ReactivationModule.model.employeeStatus];
  }

  get tabsNav(): TabsNavInterface {
    return TasksEntityModule.tabsNav;
  }

  get currentTab(): string {
    return TasksEntityModule.tabsNav.reactivation?.id;
  }

  get settings(): PageInterface {
    return ReactivationModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return ReactivationModule.filter.filterSettings;
  }

  get inlineFilterSettings(): FilterModel {
    return ReactivationModule.inlineFilter.filterSettings.filterModel;
  }

  get showReactivationModal(): boolean {
    return ReactivationModule.showReactivationModal;
  }

  get employeeStatuses(): Checkbox[] {
    return ReactivationModule.employeeStatuses;
  }

  convertEmployeeStatus(id: number): string {
    let name = '';
    this.employeeStatuses.map((item) => {
      if (item.id === id) {
        name = item.name;
      }
    });

    return name;
  }

  async resetFilters(): Promise<void> {
    await ReactivationModule.setPageToBegin();
    await ReactivationModule.filter.resetFilter();
    await ReactivationModule.getList();
  }

  async updateList(): Promise<void> {
    await ReactivationModule.setPageToBegin();
    await ReactivationModule.filter.updateFilter();
    await ReactivationModule.getList();
  }

  async selectRegion(selected: {}): Promise<void> {
    ReactivationModule.SET_REGION({
      key: 'geoRegion',
      value: selected,
    });

    await ReactivationModule.inlineFilter.updateFilter();
    await ReactivationModule.getList();
  }

  async updateCheckboxFilter(key: string, value: boolean): Promise<void> {
    ReactivationModule.inlineFilter.updateCheckbox({
      key,
      checkbox: {
        bool: value,
        id: key,
      },
    });

    await ReactivationModule.inlineFilter.updateFilter();
    await ReactivationModule.getList();
  }

  sort(header: { id: string; sort: { value: string } }): void {
    ReactivationModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  copy(item: { fullName: { fullName: string } }): void {
    navigator.clipboard
      .writeText(`${item.fullName.fullName}`.trim())
      .then(() => {
        ResponseHandlerModule.showNotify({ message: 'ФИО скопированы', type: 'ok' });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  selectAmount(value: string): void {
    ReactivationModule.updatePageAmountItems(value);
  }

  openProcessedModal(id: string): void {
    ReactivationModule.lockTask(id).then((result: { status: string }) => {
      if (result.status === 'ok') {
        ReactivationModule.readTask(id);
      }
    });
  }

  closeModal(): void {
    ReactivationModule.releaseTask();
    ReactivationModule.setShowReactivationModal(false);
  }

  async updateViewed(id: string, value: boolean): Promise<void> {
    this.disabledViewCheckboxes.push(id);
    await ReactivationModule.updateViewed({ id, value });

    const checkboxIndex = this.disabledViewCheckboxes.indexOf(id);
    this.disabledViewCheckboxes.splice(checkboxIndex, 1);
  }

  async makeCall(employeeId: string): Promise<void> {
    try {
      const phone = await getEmployeePhone(employeeId);
      makePhoneCall(phone);
    } catch (error) {
      console.error(error);
    }
  }

  async mounted(): Promise<void> {
    await TasksEntityModule.initTabsItems();
    await ReactivationModule.init();
    await ReactivationModule.initTaskStatusesSelect();
    await ReactivationModule.initEmployeeStatusesSelect();
    await ReactivationModule.initSplitSelect();
  }
}

import FilterFactory from '@/lib/layouts/page/filterFactory';
import { EmployeeViewType } from '@/lib/util/consts';

export default class InlineReactivationFilter {
  filterHandlers = {
    update: 'reactivationInlineFilter/updateFilter',
    reset: 'reactivationInlineFilter/resetFilter',
    resetState: 'reactivationInlineFilter/resetFilterState',
    removeSelected: 'reactivationInlineFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createMultiSelectEntity('geoRegion');
    filter.setName('geoRegion', 'multi_select_region');
    filter.setPreviewName('geoRegion', 'Гео-регион');
    filter.setInitAction('geoRegion', 'reactivation/initRegionsSelect');
    filter.setAction('geoRegion', 'filterModule/updateMultiSelect');

    filter.createCheckboxEntity('viewed');
    filter.setName('viewed', 'checkbox_viewed');
    filter.setAction('viewed', 'filterModule/updateCheckbox');
    filter.setListElement('viewed', {
      id: 'viewed',
      name: 'Просмотренные',
      checked: false,
    });

    filter.createSearchEntity('view');
    filter.setName('view', 'search_view');
    filter.setValue('view', EmployeeViewType.Statuses);

    filter.createCheckboxEntity('processed');
    filter.setTitle('processed', 'Обработанные');
    filter.setPreviewName('processed', 'Обработанные');
    filter.setName('processed', 'checkbox_viewed');
    filter.setAction('processed', 'reactivation/updateIsProcessedFilter');
    filter.setListElement('processed', {
      id: 'processed',
      name: 'Обработанные',
      checked: false,
    });

    this.filterModel = filter.filterModel;
  }
}

import { Action, Module } from 'vuex-module-decorators';
import moment from 'moment';
import store from '@/store';
import FilterModule, { PreparedFilter } from '@/store/filter';
import { DatePickerValue } from '@/components/form/DatePickerMixin';

@Module({ dynamic: true, store, name: 'reactivationFilter', namespaced: true })
export default class ReactivationFilter extends FilterModule {
  constructor(module: ReactivationFilter) {
    super(module);
  }

  @Action({ rawError: true })
  async initDates(): Promise<{ startDate: DatePickerValue; endDate: DatePickerValue }> {
    await this.self.setFilterFromLocalStorage();

    const { noShiftsPast, noShiftsFuture } = this.self.filterSettings.filterModel;
    const startDate = this.self.getDateByDiff(-(noShiftsPast.value ?? 0));
    const endDate = this.self.getDateByDiff(+(noShiftsFuture.value ?? 0));

    return { startDate, endDate };
  }

  @Action({ rawError: true })
  prepareDatePicker(): PreparedFilter {
    return {
      string: '',
      preview: {},
    };
  }

  @Action({ rawError: true })
  async updateStartDate(params: { key: string; date: DatePickerValue }): Promise<void> {
    this.self.updateNoShiftsByDate('noShiftsPast', params.date);
    super.updateStartDate(params);
  }

  @Action({ rawError: true })
  async updateEndDate(params: { key: string; date: DatePickerValue }): Promise<void> {
    this.self.updateNoShiftsByDate('noShiftsFuture', params.date);
    super.updateEndDate(params);
  }

  @Action({ rawError: true })
  async blurStartDate(params: { key: string; date: DatePickerValue }): Promise<void> {
    if (this.self.checkIsCurrentDate(params.date)) {
      super.updateStartDate({ key: params.key, date: { day: '', month: '', year: '' } });
    }
  }

  @Action({ rawError: true })
  async blurEndDate(params: { key: string; date: DatePickerValue }): Promise<void> {
    if (this.self.checkIsCurrentDate(params.date)) {
      super.updateEndDate({ key: params.key, date: { day: '', month: '', year: '' } });
    }
  }

  @Action({ rawError: true })
  async resetSearch(key: string): Promise<void> {
    const noShiftsMethods = {
      noShiftsPast: 'updateStartDate',
      noShiftsFuture: 'updateEndDate',
    } as const;

    if (Object.keys(noShiftsMethods).includes(key)) {
      const updateNoShiftsMethod = noShiftsMethods[key as keyof typeof noShiftsMethods];
      this[updateNoShiftsMethod]({ key: 'date', date: { day: '', month: '', year: '' } });
    }

    super.resetSearch(key);
  }

  private checkIsCurrentDate(date: DatePickerValue): boolean {
    const nowDate = moment().format('DD/MM/YY');
    const dateForCheck = `${date.day}/${date.month}/${date.year}`;

    return nowDate === dateForCheck;
  }

  private updateNoShiftsByDate(noShiftsKey: string, date: DatePickerValue): void {
    let noShiftsValue = '';

    if (date.day) {
      const newDateFormatted = [date.year, date.month, date.day].join('-');
      const nowDate = moment().startOf('day');

      let diffDays = nowDate.diff(moment(newDateFormatted, 'YY-MM-DD'), 'days');
      diffDays = Math.abs(diffDays);

      if (diffDays) {
        noShiftsValue = diffDays.toString();
      }
    }

    this.self.updateSearch({ key: noShiftsKey, value: noShiftsValue });
  }

  private getDateByDiff(daysDiff: number): DatePickerValue {
    const result = { day: '', month: '', year: '' };

    if (daysDiff) {
      const momentDate = moment().add(daysDiff, 'days');

      result.day = momentDate.format('DD');
      result.month = momentDate.format('MM');
      result.year = momentDate.format('YY');
    }

    return result;
  }
}

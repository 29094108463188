
import { Component, Vue } from '@/lib/decorator';

import { GuiTextField } from '@library/gigant_ui';
import { ReactivationTask } from '@/interfaces/reactivation.interface';
import nextCallTime from '@/views/tasks/reactivation/nextCallTime';
import IconFont from '@/components/icons/IconFont.vue';
import { formatPhone } from '@/lib/Utils';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import FormSelect from '@/components/form/Select/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';

import FormTimestampPicker from '@/components/form/TimePicker/TimestampPickerFilter.vue';
import ReactivationModule from '@/store/tasks/reactivation';
import moment from 'moment';

@Component({
  components: {
    IconFont,
    FormSelect,
    ModalBtnBottom,
    FormButton,
    FormTimestampPicker,
    GuiTextField,
  },
})
export default class ReactivationCard extends Vue {
  status: { id: string | null; value: string } = { id: null, value: '' };
  split: { id: string | null; value: string } = { id: null, value: '' };
  comment = this.model.comment ?? '';
  nextContact = this.model.nextContact ?? nextCallTime;

  errorStatus = '';
  errorSplit = '';
  errorComment = '';
  errorNextContact = '';

  get model(): ReactivationTask {
    return ReactivationModule.model;
  }

  get taskStatuses() {
    return ReactivationModule.taskStatuses;
  }

  get splitReasons() {
    return ReactivationModule.splitReasons;
  }

  get lastContact(): string {
    if (this.model.lastContact) {
      return moment(this.model.lastContact).format('DD.MM.YYYY HH:mm');
    }

    return '';
  }

  get currentDate(): string {
    return moment(new Date().setMinutes(new Date().getMinutes() + 10)).format('DD/MM/YYYY HH:mm');
  }

  get normalizedNextContact(): string {
    const DEFAULT_TIMEZONE = 3;

    if (+this.model.employee.timezoneNumber !== DEFAULT_TIMEZONE) {
      return moment(`${this.nextContact.date} ${this.nextContact.time}`, 'DD/MM/YYYY HH:mm')
        .utcOffset(this.model.employee.timezoneNumber)
        .format('DD/MM/YYYY HH:mm');
    }

    return '';
  }

  updateNextContact(value: { date: { day: string; month: string; year: string }; hour: string; minute: string }): void {
    this.errorNextContact = '';
    this.nextContact.date = `${value.date.day}/${value.date.month}/${value.date.year}`;
    this.nextContact.time = `${value.hour}:${value.minute}`;
  }

  formatPhone(value: string): string {
    return formatPhone(value);
  }

  copy(): void {
    navigator.clipboard
      .writeText(`${this.model.employee.phone}`.trim())
      .then(() => {
        ResponseHandlerModule.showNotify({ message: 'Номер телефона скопирован', type: 'ok' });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleStatusSelect(selected: { id: string; value: string }): void {
    this.status = selected;
  }

  handleReasonSelect(selected: { id: string; value: string }): void {
    this.split = selected;
  }

  validateComment(): boolean {
    this.errorComment = '';

    if (this.comment.length < 3) {
      this.errorComment = 'Минимальная длина комментария: 3 символа';
    } else if (this.comment.length > 1024) {
      this.errorComment = 'Максимальная длина комментария: 1024 символа';
    }

    return !this.errorComment;
  }

  validateNextContact(): boolean {
    this.errorNextContact = '';

    const isEmptyNextContact = this.nextContact.date === '' || this.nextContact.time === '';
    const momentNextContactDate = moment(`${this.nextContact.date}T${this.nextContact.time}`, 'DD/MM/YYYYTHH:mm');
    const isPastNextContactDate = momentNextContactDate.diff(moment()) < 0;

    if (isPastNextContactDate) {
      this.errorNextContact = 'Не может быть раньше текущей даты';
    } else if (isEmptyNextContact) {
      this.errorNextContact = 'Не указана дата следующего звонка';
    }

    return !this.errorNextContact;
  }

  async save(): Promise<void> {
    const data = {};

    const isInvalidNextContact = !this.validateNextContact();
    const isInvalidComment = !this.validateComment();

    Object.assign(data, {
      ...data,
      status: this.status.id,
      comment: this.comment,
    });

    switch (this.status.id) {
      case 'contact_later': {
        if (isInvalidNextContact || isInvalidComment) {
          return;
        }

        const nextContactDate = this.nextContact.date.split('/');
        Object.assign(data, {
          ...data,
          nextContact:
            nextContactDate[2] + '-' + nextContactDate[1] + '-' + nextContactDate[0] + 'T' + this.nextContact.time + ':00',
        });

        break;
      }
      case 'finally_deactivated': {
        if (!this.split.id) {
          this.errorSplit = 'Поле не заполнено';

          return;
        }

        this.errorSplit = '';
        Object.assign(data, {
          ...data,
          split: this.split.id,
        });

        if (isInvalidComment) {
          return;
        }

        break;
      }
      case 'done': {
        if (isInvalidComment) {
          return;
        }

        break;
      }
    }

    ReactivationModule.updateTask(data).then(() => {
      ReactivationModule.releaseTask();
      ReactivationModule.setShowReactivationModal(false);
      ReactivationModule.updateList();
    });
  }

  mounted(): void {
    setTimeout(() => {
      if (this.model.status) {
        this.taskStatuses.map((item: { id: string; value: string }) => {
          if (item.id === this.model.status) {
            this.status = item;
          }
        });
      }

      if (this.model.split) {
        this.splitReasons.map((item: { id: string; value: string }) => {
          if (item.id === this.model.split) {
            this.split = item;
          }
        });
      }
    });
  }
}
